import ProGalleryStore from './ProGalleryStore';
import { get } from 'lodash';
import {
  Utils,
  getAlbumData as utilsGetAlbumData,
  isUseArtStoreInAlbumsExperimentOn,
} from '../utils';

export default class AlbumsStore extends ProGalleryStore {
  constructor(
    context,
    wixCodeApi,
    platformServices,
    scopedGlobalSdkApis,
    APP_DEFINITION_ID,
    GALLERY_WIDGET_ID,
    isArtStore,
    setNewStyleParamsCallback,
    metaSiteId,
  ) {
    super(
      context,
      wixCodeApi,
      platformServices,
      scopedGlobalSdkApis,
      APP_DEFINITION_ID,
      GALLERY_WIDGET_ID,
      isArtStore,
      setNewStyleParamsCallback,
    );
    this.metaSiteId = metaSiteId;
    this.albumData = null;
    this.baseUrl = Utils.getBaseUrl(
      this.wixCodeApi,
      Utils.parseViewMode(this.wixCodeApi.window.viewMode),
    );
  }

  async getAlbumData() {
    if (!this.albumData) {
      this.albumData = await utilsGetAlbumData(this.baseUrl, this.metaSiteId);
    }
    return this.albumData;
  }

  async getGalleryDataFromServer(from, options = {}) {
    try {
      if (
        !(await isUseArtStoreInAlbumsExperimentOn(this.scopedGlobalSdkApis))
      ) {
        return await super.getGalleryDataFromServer(from, options);
      }
      //TODO: Dor - Find different way, check for "set" param
      this.galleryId = get(this.wixCodeApi, 'location.path[0]');
      const albumData = await this.getAlbumData();
      if (albumData && this.galleryId) {
        this.shouldGetStoreDataFromAlbum = true;
        albumData.styleParams &&
          this.setNewStyleParamsCallback(albumData.styleParams);

        const settings = {
          watermark: albumData.watermark,
        };

        const response = await super.getGalleryDataFromServer(
          from,
          options,
          settings,
        );

        return response;
      }
    } catch (ex) {}
    return await super.getGalleryDataFromServer(from, options);
  }
}
