import { get } from 'lodash';
import { experimentsWrapper } from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import { Utils } from './utils';

export async function getAlbumData(baseUrl, metaSiteId) {
  try {
    const response = await fetch(
      baseUrl +
        `/albums-node-server/getAlbumDataForSite?metaSiteId=${metaSiteId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    return data && data.settings;
  } catch (ex) {
    return null;
  }
}

export function shouldGetAlbumData(wixCodeApi) {
  return (
    wixCodeApi.location.prefix === 'set' &&
    Boolean(get(wixCodeApi, 'location.path[0]'))
  );
}

export async function isUseArtStoreInAlbumsExperimentOn(scopedGlobalSdkApis) {
  await Utils.verifyExperiments(scopedGlobalSdkApis);
  return experimentsWrapper.getExperimentBoolean(
    'specs.pro-gallery.useArtStoreInAlbums',
  );
}
